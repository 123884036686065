.feature {

    padding: 5em 0;
    background-color: orange;

    h1, h2 {
        font-family: $header-font-family;
        color: white;
        margin: 0;
        padding: 0;
    }

    h2 {
        font-family: $base-font-family;
        font-weight: normal;
        font-size: 14pt;
    }

    p {
        color: white;
    }

    .button {
        margin-top: 1em;
        background-color: #84a1d0;

        &:hover {
            background-color: #90b0e5;
        }

        &.success {
            background-color: #43AC6A;

            &:hover {
              background-color: #5abf7f;
            }
        }

    }

    &.citrus {
        background: url(/assets/img/citrus-background.jpg) no-repeat bottom center;
        background-size: cover;
    }

    &.jzt {
        background-color: #777;
        background: repeating-radial-gradient(circle, #555, #555 20px, #5A5A5A 20px, #5A5A5A 40px);
    }

}

.content {

    margin-top: 2em;
    color: #555;

    h2, h3 {
        font-family: $header-font-family;
        color: orange;
    }

    h2 a, h3 a {
        text-decoration: none;
        color: orange;
    }

    img {
        margin: auto;
    }
}

.outside-references {
    font-size: 8pt;
}
