body {
    font-family: $base-font-family;
    font-color: black;
}

h1, h2 {
    color: orange;
}

.button {
    color: white;
    padding: 1em 3em;
    text-decoration: none;
    background-color: orange;
    display: inline-block;
    text-align: center;
}

small {
    font-size: 75%;
}

a {
    color: orange;
}

section {
  margin-top: 5em;
}

.callout {

  &.success {
    background-color: #FFF7ED;
    border: 1px solid rgba(10, 10, 10, 0.25);
    padding: 1rem;
  }

}

ul {

  list-style: circle;

  li {
    margin-bottom: 1em;
  }

}

footer {

    background-color: #EEE;
    margin-top: 1em;

    p.nav {
      text-align: right;
    }

    p {
        font-size: 8pt;
        color: gray;

        a {
            color: gray;
        }
    }
}
