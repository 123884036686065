@charset "utf-8";

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTaRDOzjiPcYnFooOUGCOsRk.woff) format('woff');
}

@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: local('Quicksand Regular'), local('Quicksand-Regular'), url(https://fonts.gstatic.com/s/quicksand/v5/sKd0EMYPAh5PYCRKSryvW7O3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
}

// Our variables
$base-font-family: 'Open Sans', Helvetica, Arial, sans-serif;
$header-font-family: Quicksand, Helvetica, Arial, sans-serif;

@import 'main';
@import 'header';
@import 'homepage';
@import 'post';
