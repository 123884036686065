$header-text-color: orange;
$header-background-color: white;

@keyframes orangeblur {
  from { text-shadow: 0 0 5px $header-background-color; color: transparent; }
  to   { text-shadow: 0; color: $header-background-color; }
}

header {

    font-family: $header-font-family;
    padding: 2em 0 2em 0;
    background-color: $header-background-color;
    background-size: 100%;
    box-shadow: 0 2px 3px rgba(0,0,0,0.25);
    position: relative;
    z-index: 99;

    h1 {

        color: $header-text-color;
        font-weight: normal;
        font-size: 30px;
        line-height: 45px;
        padding: 0;
        margin: 0;

        a {
            text-decoration: none;
            color: $header-text-color;
            transition: all 1s ease;

            &:hover {
                color: transparent;
                text-shadow: 0 0 5px $header-text-color;
                transition: all 0.15s ease;
            }

        }

    }

    nav {

        text-align: right;
        line-height: 45px;

        ul, ul li {

            display: inline;
            list-style: none;
            margin: 0;
            padding: 0 0 0 1em;

            a:hover {
                transition: all .2s ease-out;
                text-shadow: 0 0 5px $header-text-color;
            }

        }

        a {
            text-decoration: none;
            color: $header-text-color;
            transition: all 1s ease;
        }

    }

}

ul.products {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
