.feature {

  &.generic {
      background: url(/assets/img/sky-background.jpg) no-repeat bottom center;
      background-size: cover;
      padding: 2em 5em;
  }

  &.secondary .button {
      background-color: white;
      color: orange;
      padding: .5em 1em;
      font-size: 16px;
      border: none;
  }

  &.secondary input {
    font-size: 16px;
    min-width: 200px;
    width: 50%;
    margin-right: 3px;
    padding: .5em 1em;
    border: 1px solid white;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }

}
